jQuery(document).ready(function() {
    "use strict";

    /*  Menu */
    // slideEffectAjax()
    // jQuery(".toggle").on("click", function() {
    //         return jQuery(".submenu").is(":hidden") ? jQuery(".submenu").slideDown("fast") : jQuery(".submenu").slideUp("fast"), !1
    //     }), jQuery(".topnav").accordion({
    //         accordion: !1,
    //         speed: 300,
    //         closedSign: "+",
    //         openedSign: "-"
    //     }),
    /*Loader*/

    // var navHeight = $('nav').height();
    // console.log('marginTop:' + navHeight + 'px')
    // function updateBodyMarginTop() {
    //     $('body').css({
    //         marginTop: $('nav').height() + 'px'
    //     });

    // }
    
    
    // $(window).load(function() {
    //     $('.loader-mask').fadeOut(800, function() {});
    //     // $('body').css({
    //     //     marginTop: $("nav").height() + 'px'
    //     // });
    //     updateBodyMarginTop();
    // });
    // $(window).resize(updateBodyMarginTop);

    $(window).on('load', function(){ 
        $('.loader-mask').fadeOut(800, function() {});
        // updateBodyMarginTop();
        new WOW().init();
    });
    
    // $(window).resize(function() {
    //     $('body').css({
    //         marginTop: $("nav").height() + 'px'
    //     });
    // });
    // $(window).trigger('load');
    

    $("#nav > li").hover(function() {
        var e = $(this).find(".level0-wrapper, .main-drop");
        e.hide(), e.css("left", "0"), e.stop(true, true).fadeIn(300, "easeOutCubic")
    }, function() {
        $(this).find(".level0-wrapper, .main-drop").stop(true, true).fadeOut(300, "easeInCubic")
    });
    $(".map-wrapper").on({
        mouseleave: function() {
            $(this).find('iframe').css('pointer-events', 'none');
        },
        click: function() {
            $(this).find('iframe').css('pointer-events', 'auto');
        }
    }).find('iframe').css('pointer-events', 'none');

    
    
    

    /*  Sidebar Menu */
    jQuery("ul.accordion li.parent, ul.accordion li.parents, ul#magicat li.open").each(function() {
        jQuery(this).append('<em class="open-close">&nbsp;</em>')
    }), jQuery("ul.accordion, ul#magicat").accordionNew(), jQuery("ul.accordion li.active, ul#magicat li.active").each(function() {
        jQuery(this).children().next("div").css("display", "block")
    })

    /*  Cart  */
    function deleteCartInCheckoutPage() {
        return jQuery(".checkout-cart-index a.btn-remove2,.checkout-cart-index a.btn-remove").on("click", function(e) {
            return e.preventDefault(), confirm(confirm_content) ? void 0 : !1
        }), !1
    }
    jQuery(".subDropdown")[0] && jQuery(".subDropdown").on("click", function() {
            jQuery(this).toggleClass("plus"), jQuery(this).toggleClass("minus"), jQuery(this).parent().find("ul").slideToggle()
        })
        /*  Top Cart */
        // function slideEffectAjax() {
        //     $(".mini-cart").click(function() {
        //         $(".top-cart-content").slideToggle(300);
        //     }),
        //     $(".top-cart-content").mouseleave(function() {
        //         $(".top-cart-content").slideUp(300);
        //     });
        // }

    // var viewportwidth = window.innerWidth;
    // if (viewportwidth > 767) {
    //     $('.header-top').on('mouseenter', '.top-cart-contain .mini-cart', function (e) {
    //         e.preventDefault();
    //         $('.top-cart-content').fadeIn(300);
    //     });
    //     $('.header-top, .top-cart-content').on('mouseleave', function (e) {
    //         e.preventDefault();
    //         $('.top-cart-content').fadeOut(300);
    //     });
    // } else {
    //     $('.header-top').on('click', '.mini-cart',  function (e) {
    //         e.preventDefault();
    //         $('.top-cart-content').fadeToggle(300);
    //     }); 
    // } 
    // $('.header-top').on('mouseenter', '.top-cart-contain .mini-cart', function(e) {
    //     e.preventDefault();
    //     $('.top-cart-content').fadeIn(300);
    // });
    // $('.header-top, .top-cart-content').on('mouseleave', function(e) {
    //     e.preventDefault();
    //     $('.top-cart-content').fadeOut(300);
    // });
    // $('.header-top').on('click', '.mini-cart', function(e) {
    //     e.preventDefault();
    //     $('.top-cart-content').fadeToggle(300);
    // });
    // $('.top-cart-contain').hover(function() {
    //     event.preventDefault();
    //     $('.top-cart-content').stop().slideToggle(200);
    // });   
})
$(function() {
    $("#menu").mmenu({
        extensions: [
            "border-full",
            "effect-slide-panels-100",
            "theme-dark",
        ],
        counters: true,
        offCanvas: {
            position: "left",
            zposition: "front"
        },
        // dragOpen: {
        //     open: true,
        // },
        navbars: [{
            position: "top",
            content: ["prev", "title"],
        }, {
            position: "bottom",
            height: 1,
            // content: [
            //     '<a href="#/">繁體中文</a>',
            //     '<a href="#/">简体中文</a>',
            //     '<a href="#/">English</a>',
            // ]
        }]

    });
    

});
$("#search-icon, .mobile-search-icon").click(function() {
    $(".search-area").fadeIn(300);
    $(".search-overlay").fadeIn(300);
    $("#search-form input").focus();
});
$(".search-overlay").click(function() {
    $(".search-area").fadeOut(300);
    $(".search-overlay").fadeOut(300);
});
$("#search-products .fa-remove").click(function() {
    $("#search-form input").val("");
});


// $("#search-form input").blur(function() {
//     $("#search-form").fadeOut(300)
// });


var isTouchDevice = "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
jQuery(window).on("load", function() {
        isTouchDevice && jQuery("#nav a.level-top").on("click", function() {
            if ($t = jQuery(this), $parent = $t.parent(), $parent.hasClass("parent")) {
                if (!$t.hasClass("menu-ready")) return jQuery("#nav a.level-top").removeClass("menu-ready"), $t.addClass("menu-ready"), !1;
                $t.removeClass("menu-ready")
            }
        }), jQuery().UItoTop()
    }),
    jQuery(window).scroll(function() {
        jQuery(this).scrollTop() > 1 ? $("nav").addClass("sticky") : jQuery("nav").removeClass("sticky")
    }),


    /*  ToTop */
    function(e) {
        jQuery.fn.UItoTop = function(t) {
            var a = {
                    text: "",
                    min: 200,
                    inDelay: 600,
                    outDelay: 400,
                    containerID: "toTop",
                    containerHoverID: "toTopHover",
                    scrollSpeed: 300,
                    easingType: "linear"
                },
                i = e.extend(a, t),
                n = "#" + i.containerID,
                s = "#" + i.containerHoverID;
            jQuery("body").append('<a href="#" id="' + i.containerID + '">' + i.text + "</a>"), jQuery(n).hide().on("click", function() {
                return jQuery("html, body").animate({
                    scrollTop: 0
                }, i.scrollSpeed, i.easingType), jQuery("#" + i.containerHoverID, this).stop().animate({
                    opacity: 0
                }, i.inDelay, i.easingType), !1
            }).prepend('<span id="' + i.containerHoverID + '"></span>').hover(function() {
                jQuery(s, this).stop().animate({
                    opacity: 1
                }, 600, "linear")
            }, function() {
                jQuery(s, this).stop().animate({
                    opacity: 0
                }, 700, "linear")
            }), jQuery(window).scroll(function() {
                var t = e(window).scrollTop();
                "undefined" == typeof document.body.style.maxHeight && jQuery(n).css({
                    position: "absolute",
                    top: e(window).scrollTop() + e(window).height() - 50
                }), t > i.min ? jQuery(n).fadeIn(i.inDelay) : jQuery(n).fadeOut(i.Outdelay)
            })
        }
    }(jQuery),
    jQuery.extend(jQuery.easing, {
        easeInCubic: function(e, t, a, i, n) {
            return i * (t /= n) * t * t + a
        },
        easeOutCubic: function(e, t, a, i, n) {
            return i * ((t = t / n - 1) * t * t + 1) + a
        }
    }),
    /* Accordian */
    jQuery.extend(jQuery.easing, {
        easeInCubic: function(e, t, n, i, s) {
            return i * (t /= s) * t * t + n
        },
        easeOutCubic: function(e, t, n, i, s) {
            return i * ((t = t / s - 1) * t * t + 1) + n
        }
    }),
    function(e) {
        e.fn.extend({
            accordion: function() {
                return this.each(function() {})
            }
        })
    }(jQuery), jQuery(function(e) {
        e(".accordion").accordion(), e(".accordion").each(function() {
            var t = e(this).find("li.active");
            t.each(function(n) {
                e(this).children("ul").css("display", "block"), n == t.length - 1 && e(this).addClass("current")
            })
        })
    }),


    /* Responsive Nav */
    function(e) {
        e.fn.extend({
            accordion: function(t) {
                var n = {
                        accordion: "true",
                        speed: 300,
                        closedSign: "[+]",
                        openedSign: "[-]"
                    },
                    i = e.extend(n, t),
                    s = e(this);
                s.find("li").each(function() {
                    0 != e(this).find("ul").size() && (e(this).find("a:first").after("<em>" + i.closedSign + "</em>"), "#" == e(this).find("a:first").attr("href") && e(this).find("a:first").on("click", function() {
                        return !1
                    }))
                }), s.find("li em").on("click", function() {
                    0 != e(this).parent().find("ul").size() && (i.accordion && (e(this).parent().find("ul").is(":visible") || (parents = e(this).parent().parents("ul"), visible = s.find("ul:visible"), visible.each(function(t) {
                        var n = !0;
                        parents.each(function(e) {
                            return parents[e] == visible[t] ? (n = !1, !1) : void 0
                        }), n && e(this).parent().find("ul") != visible[t] && e(visible[t]).slideUp(i.speed, function() {
                            e(this).parent("li").find("em:first").html(i.closedSign)
                        })
                    }))), e(this).parent().find("ul:first").is(":visible") ? e(this).parent().find("ul:first").slideUp(i.speed, function() {
                        e(this).parent("li").find("em:first").delay(i.speed).html(i.closedSign)
                    }) : e(this).parent().find("ul:first").slideDown(i.speed, function() {
                        e(this).parent("li").find("em:first").delay(i.speed).html(i.openedSign)
                    }))
                })
            }
        })
    }(jQuery),
    function(e) {
        e.fn.extend({
                accordionNew: function() {
                    return this.each(function() {
                        function t(t, i) {
                            e(t).parent(l).siblings().removeClass(s).children(c).slideUp(r), e(t).siblings(c)[i || o]("show" == i ? r : !1, function() {
                                e(t).siblings(c).is(":visible") ? e(t).parents(l).not(n.parents()).addClass(s) : e(t).parent(l).removeClass(s), "show" == i && e(t).parents(l).not(n.parents()).addClass(s), e(t).parents().show()
                            })
                        }
                        var n = e(this),
                            i = "accordiated",
                            s = "active",
                            o = "slideToggle",
                            c = "ul, div",
                            r = "fast",
                            l = "li";
                        if (n.data(i)) return !1;
                        e.each(n.find("ul, li>div"), function() {
                            e(this).data(i, !0), e(this).hide()
                        }), e.each(n.find("em.open-close"), function() {
                            e(this).on("click", function() {
                                return void t(this, o)
                            }), e(this).bind("activate-node", function() {
                                n.find(c).not(e(this).parents()).not(e(this).siblings()).slideUp(r), t(this, "slideDown")
                            })
                        });
                        var a = location.hash ? n.find("a[href=" + location.hash + "]")[0] : n.find("li.current a")[0];
                        a && t(a, !1)
                    })
                }
            })
            // e(function() {
            //     function t() {
            //         var t = e('.navbar-collapse form[role="search"].active');
            //         t.find("input").val(""), t.removeClass("active")
            //     }
            //     e('header, .navbar-collapse form[role="search"] button[type="reset"]').on("click keyup", function(n) {
            //         console.log(n.currentTarget), (27 == n.which && e('.navbar-collapse form[role="search"]').hasClass("active") || "reset" == e(n.currentTarget).attr("type")) && t()
            //     }), e(document).on("click", '.navbar-collapse form[role="search"]:not(.active) button[type="submit"]', function(t) {
            //         t.preventDefault();
            //         var n = e(this).closest("form"),
            //             i = n.find("input");
            //         n.addClass("active"), i.focus()
            //     }), e(document).on("click", '.navbar-collapse form[role="search"].active button[type="submit"]', function(n) {
            //         n.preventDefault();
            //         var i = e(this).closest("form"),
            //             s = i.find("input");
            //         e("#showSearchTerm").text(s.val()), t()
            //     })
            // })

    }(jQuery);
